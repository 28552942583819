import { Link } from "gatsby" 
import React, { useEffect, useRef, useContext } from "react" 
import PropTypes from "prop-types" 
import Header from "./header"     
import { Modal } from "./layout/modal";
import { BreadCrumb } from "./layout/BreadCrumb";  
import { TopProducts } from "./elements/TopProducts";
import CookieConsent from "./elements/CookieConsent"; 
import PageContext from "../context/PageContext";
import ProductsNew from "./goods/ProductsNew";



const Layout = ({ children, location, order }) => {
  const consentRef = useRef();

  const [context] = useContext(PageContext); 
  const searchString = context?.searchString;

  useEffect(() => {
    consentRef.current.setAttribute("data-cc","c-settings") 
    // inputRef.current.setAttribute('de-di-var', '');
  }, []);
  

  let path
  let cat 
  if(location) {
    path = location?.pathname?.split('/');
     
  } 
 
  if(path && path[2]!=='strana') {
      cat = path[2]
  }
   
  return (
    <> 
    <div className="relative shadow-md">
      <Header location={location} cat={cat} order={order} /> 
      <main className={"relative  pb-10  lg:pb-32 " + (!order && "pt-16 lg:pt-0") }> 
           
              <BreadCrumb />

              {searchString ? <><ProductsNew location={location} /></> :  children}   
      </main>
      
    </div>
    {!order && <TopProducts />}
    <footer className="bg-gray-300 text-gray-700" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Patička
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 prose prose-sm prose-amber ">
          <div className="text-lg text-gray-600">Informace a podmínky</div>
            <ul>
              <li><Link to={'/doprava/'} className="hover:no-underline">Doprava</Link></li>
              <li><Link to={'/vseobecne-obchodni-podminky/'} className="hover:no-underline">Obchodní podmínky</Link></li>
              <li><Link to={'/reklamacni-rad/'} className="hover:no-underline">Reklamační řád</Link></li>
              <li><Link to={'/kontakt/'} className="hover:no-underline">Kontakt</Link></li>
            </ul>
        </div> 
        <div className="mt-8 border-t border-gray-700 border-opacity-20 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            <button type="button" ref={consentRef} data-cc="c-settings">Nastavení cookies</button>
            
          </div>
          <p className="mt-8 text-base   md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()}  
 
          </p>
        </div>
      </div>
    </footer>
      <Modal />
      <CookieConsent  />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
