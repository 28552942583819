import { Link } from "gatsby"
import React, { useEffect, useState }  from "react"
 

export const SubCats = ({cats}) => {
    
    const [catList, setCatList] = useState([]);
 
    function importAll(r) {
        const keys = r.keys();
        const values = keys.map(r);
        let newValues = [];

        keys.forEach(function(val, keyIdx) {
            var tmp = {
                name: val.replace(/\/|.svg|\./g,''),
                function: values[keyIdx]
            };
             
            newValues[keyIdx] = tmp;           
        });
         
        return newValues;
     }
    

    useEffect(() => {
         
        const icons = importAll(require.context('../../icons/', false, /\.(svg)$/));
         
            
        function menuItems() {   
            
            let newItems = []
            cats && cats.forEach((menuItem) => {
                let newMenuItem = {...menuItem};

                icons.forEach((icon) => {
                    if(menuItem.url === icon.name) {
                        newMenuItem.icon = icon.function
                    }
                })
                newItems.push(newMenuItem);
                })
                return newItems;
            }

        let newCats = menuItems();
        setCatList(newCats);

        // eslint-disable-next-line
    }, [cats]); 

    return( 
        <>
        {catList && catList.length > 0 && 
            <nav aria-label="Subcategories" className="text-sm font-bold pb-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 "> 
                {catList && catList.map((page) => ( 
                    <Link
                        key={page.name}
                        to={'/plysaci/' + page.url + '/'}
                        className=" p-3 space-x-2 bg-teal-600 bg-opacity-10 text-teal-800 rounded-md  border flex items-center justify-center  hover:border-teal-600 hover:text-white hover:bg-teal-600   "
                        aria-current={page.current ? 'page' : undefined}
                    >
                        {page.icon && <span ><page.icon className="w-5 h-5" fill="currentColor" /></span>}
                        <span>{page.name}</span>
                    </Link> 
                
                ))}
             
            </nav>
        }
        </>
    )
}