import {gql} from '@apollo/client';

export const GOODS_LIST = gql`
    query goodsCategory($cat: String, $limit: Int, $offset: Int, $storage: Int, $params: [String!], $orderBy: [String!]) {
        goodFindAll(category: $cat, limit: $limit, offset: $offset, storage: $storage, noFiles: 1, noCats: 1, params: $params, orderBy: $orderBy) {
            id
            name
            img
            alias
            priceVat
            supRrp
            quantity
            description
            size
            note
        },
        goodGetCount(category: $cat, storage: $storage, params: $params)
        goodCategoryFindByUrl(url:$cat) {
    		name
    		content
  		},
        goodCategoryFindAll(cat:$cat) {
            id
    		name
    		url
  		},
        goodCategoryGetPath(cat: $cat) {
            id
            name
            url
        },
    }
`;

export const GOODS_SEARCH = gql`
    query goodsCategory($search: String!, $noFiles: Int, $orderBy: [String!]) {
        goodFindAll(search: $search, limit: 50, noFiles: $noFiles, noCats: 1, orderBy: $orderBy) {
            id
            name
            img
            alias
            priceVat
            supRrp
            quantity
            description
            size
            note
        }
    }
`;

export const GOOD_DETAIL = gql`
    query goodDetail($id: ID!) {
        goodFindOne(id: $id) {
            id
            quantity
            priceVat
            note
        }
    }
`;
 