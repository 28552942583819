import { ApolloProvider } from "@apollo/client";
import React from "react";
import { apolloClient  } from "../../client/apollo";
import ProductsList from "./ProductsList";
import {Store} from "./store/store";

export const ProductsNew = ({cartId, addItem, hideHeader, query, orderBy, location, params, page, cat, paging, producer}) => {


    return (
    <ApolloProvider client={apolloClient}>
        <Store>
            <ProductsList
                cat={cat}
                hideHeader={hideHeader}
                addItem={addItem}
                query={query}
                paging={paging}
                // tyhle parametry brat z url
                params={params}
                orderBy={orderBy}
                page={page}
                tags={[]}
                location={location}
                producer={producer}
            />
        </Store>
    </ApolloProvider>)
}

export default ProductsNew;