import { graphql, useStaticQuery, Link } from "gatsby";
import React, {useContext, useState, useEffect, Fragment } from "react"
import {default as Logo} from '../images/plysovnik-wide.svg';
import { MiniCart } from "./cart/miniCart";  
import {UserMenu} from "./elements/UserMenu";
import { Transition, Dialog } from '@headlessui/react' 
import MainMenuItem from "./layout/MainMenuItem" 
import PageContext from "../context/PageContext";
import { SET_MENU } from "../context/reducers";
import { Series } from "./goods/Series";
import GoodsSearch from "./goods/GoodsSearch"; 
import { BsSearch } from "react-icons/bs";
import { ChevronRightIcon } from "@heroicons/react/solid";
 

export default function Header({location, cat, order}) { 
  const data = useStaticQuery(graphql`
    query CatMenu {
      scomos {
        goodCategoryFindAll(menu:"main") {
          id,
          menuName: name,
          url,
          parent: parentId,
          lft,
          rgt,
        }
      }
    }
  `)
  
  
  const [open, setOpen] = useState(false);
  const [parent, setParent] = useState(0);
  const [catId, setCatId] = useState(0);
  const [context, dispatch] = useContext(PageContext); 
  const [displaySearch, setDisplaySearch] = useState(true); 
  const [clickSearch, setClickSearch] = useState(false); 
  const breadCrumbPath = context?.breadCrumbPath

  let path = []; 
  if(breadCrumbPath) { 
    breadCrumbPath.forEach((part) => {
      part?.url && 
      path.push(part.url) 
    });
  }
  
  let menu = [];
  if(context?.menu) {
    menu = context?.menu
  }
 
  useEffect(() => {   
    setParent(0)

    let items = []; 
    let mainId = 1;
    items[mainId] = {
      id: 1,
      name: 'plysovnik'
    }

  function menuItems() {  
      data.scomos.goodCategoryFindAll.forEach(menuItem => { 
        items[menuItem.id] = menuItem
      })

      let newItems = [...items]
      items.forEach((menuItem) => {
        if(items[menuItem.parent]) {
          if(!items[menuItem.parent]['children']) {
            newItems[menuItem.parent]['children'] = [];
          }
          newItems[menuItem.parent]['children'][menuItem.lft] = menuItem
          if(menuItem.url === cat) {
            setParent(menuItem.parent)
            setCatId(parseInt(menuItem.id))
          }
        }
        // if(lastItem === menuItem.url && menuItem.parent !== mainId) {
        //   path.push(items[menuItem.parent]?.url);
        // }
      })

      let sorted = [] 
      newItems[mainId]['children'].forEach((newMenuItem) => {
        sorted[newMenuItem.lft] = newMenuItem 
      }); 

      return sorted;
    }

   let newMenu = menuItems();
   dispatch({ type: SET_MENU, data:newMenu }); 
    // eslint-disable-next-line
  }, [cat]); 
  
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;  
      if (currentScrollY < 20) {
        !order && setDisplaySearch(true);
      } else if(currentScrollY > 100) {
        setDisplaySearch(false);

      }
    };

    order && setDisplaySearch(false);
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, []);
  
 
  return (
  <>
  {!order && 
      <div className="bg-white p-1 text-red-700 font-bold text-xs  ">
        <Link to={'/doprava'} className="max-w-7xl px-3 mx-auto flex items-center space-x-4 justify-center shadow-inner">
          <span>Sleva 100 Kč u nákupů nad 2000 Kč</span><ChevronRightIcon className="w-4 h-4" />
        </Link>    
      </div> 
  }

  <div className="shadow-md sticky   w-full z-40 top-0 ">
    <div className="border-b border-white bg-gray-200 bg-opacity-80 backdrop-filter backdrop-blur-sm ">
      <div className={" border-t-1 border-white " }>
        <div className={"max-w-7xl mx-auto lg:py-2  " }>
          <div className="lg:mx-5 grid grid-cols-2 lg:flex justify-between items-center lg:justify-start ">
            <div className="flex justify-start lg:w-1/4 lg:flex-1  ">
              <Link to={'/'} className="p-3 " >
                <span className="sr-only"> </span>
                <img src={Logo} className={" h-12 w-40 lg:w-auto transition-all duration-150   ease-in-out " } alt="Logo" />
              </Link>
            </div>
            {!order && 
              <div className={"w-full lg:w-1/2 order-3 lg:order-2 col-span-2 absolute lg:relative top-16 lg:top-0 mt-2 lg:mt-0  bg-red-700 lg:bg-transparent transition-all duration-150   ease-in-out  lg:block lg:opacity-100 lg:h-auto " + ((displaySearch || clickSearch) ? "translate-y-0  h-16 opacity-100 " : "  h-0 overflow-hidden opacity-0  ") }>
                <div className=" m-3 ">
                  <GoodsSearch location={location}   />

                </div>
              </div>
            
            }
            <div className="lg:order-3 lg:w-1/4 flex justify-end p-3">
              <div className="lg:order-2 flex items-center justify-end lg:flex-1 lg:ml-10 space-x-2">
                {!order && 
                  <div className={" cursor-pointer rounded-full p-2 relative justify-center items-center flex lg:hidden transition-all duration-150   ease-in-out   text-gray-600   " + (clickSearch ? "bg-white text-gray-600 h-10  w-10 " : displaySearch ? "opacity-0 h-0 w-0" : "opacity-100  h-10  w-10 ") }>     
                           <BsSearch className="w-5 h-5 " onClick={() => setClickSearch(!clickSearch)} />
                  </div>
                }
                <UserMenu  />
    
                
                <MiniCart  /> 

              </div>
              <div className=" lg:hidden ml-5 ">
                <button onClick={() => setOpen(true) }  type="button" className="lg:hidden lg:w-0 p-2 inline-flex items-center justify-center text-gray-800 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
                  <span className="sr-only">Open menu</span>
                  
                  <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={"bg-red-700 " + (order && "hidden")}>
          <div className="bg-red-700 max-w-7xl   mx-auto  px-3 ">
              <nav className="hidden lg:flex tracking-tight space-x-1   max-w-7xl mx-auto px-5 lg:py-2  "> 
                <Series setOpen={setOpen} />
              {menu.map((page, i) => (
                    <MainMenuItem item={page} key={i} setOpen={setOpen} parent={parent} catId={catId} path={path} /> 
                ))} 

              </nav>

            <div className=" lg:hidden  ">
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 overflow-hidden  z-50 " onClose={setOpen}>
                  <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25 lg:hidden backdrop-filter backdrop-blur-sm" />
                    </Transition.Child>

                    <div className="fixed lg:hidden inset-y-0 right-0   max-w-full flex">
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <div className=" w-96 h-screen shadow-lg  bg-white  ">
                            
                          <div className="bg-white" >
                            <div className="flex items-center justify-between bg-gray-200 p-4">
                              <div>
                                <img src={Logo} className="h-8  " alt="Logo" />
                              </div>
                              <div className="-mr-2">
                                <button onClick={() => setOpen(false) }  type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-700 hover:text-gray-500 hover:bg-gray-100 focus:outline-none ">
                                  <span className="sr-only">Close menu</span> 
                                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            
                          </div> 
                            <div className="bg-white   p-5">
                              <nav className="grid">
                                <Series setOpen={setOpen} />
                                {menu.map((page, i) => (
                                  <MainMenuItem item={page} key={i} setOpen={setOpen} path={path} /> 
                                ))}
                                  
                              </nav>
                            </div>
                        </div>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition.Root>

              </div>
            </div>

        </div>
      </div>
    </div>

      
  </div> 
  </>
  )
}

