import { createUploadLink } from 'apollo-upload-client'
import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client';
import { onError } from "@apollo/client/link/error";

import {CONFIG_APP_API_ROUTE} from "./index";

const uploadLink = createUploadLink(
    { 
        uri: CONFIG_APP_API_ROUTE + "graphql",
        credentials: 'include',
        // fetchOptions: {
        //     mode: 'no-cors', // asi dobry zakazat mimo dev
        // },
    });

const errorLink = onError(({graphQLErrors, networkError, operation}) => {
    const {response} = operation.getContext()
    if (response && response.status === 401) {
        response.errors = null;
    }
});

const link = ApolloLink.from([
    errorLink,
    uploadLink
]);

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: link,
});