import { useEffect } from "react";

import "vanilla-cookieconsent/src/cookieconsent.css";
 

export default function CookieConsent() {
    
    useEffect(() => {
        const cc = window.initCookieConsent();
        const dataLayer = window.dataLayer;
        function gtag(){dataLayer.push(arguments);}
            
            function consent(cookie){
                if(cookie.level.includes('analytics')) {
                    gtag('consent', 'update', {
                      'analytics_storage': 'granted',
                      'ad_storage': 'granted'
                    });
                } else {
                    gtag('consent', 'update', {
                      'analytics_storage': 'denied',
                      'ad_storage': 'denied'
                    });
                } 
            }

            
        
            // run plugin with your configuration
            cc.run({
                current_lang: 'cs',
                autoclear_cookies: true,                   // default: false 
                page_scripts: true,                   // default: false
                onAccept: function (cookie) {
                    consent(cookie);        
                },
                onChange: function (cookie) {
                    consent(cookie);        
                },
    
                languages: {
                    'cs': {
                        consent_modal: {
                            title: 'Používáme cookies!',
                            description: 'Základní cookies potřebujeme k zajištění správného fungování stránek a volitelné k lepšímu pochopení toho, jak s nimi pracujete. Ty druhé se aktivují po stisku tlačítka Souhlasím. <button type="button" data-cc="c-settings" class="cc-link">Upravit nastavení</button>',
                            primary_btn: {
                                text: 'Souhlasím',
                                role: 'accept_all'              // 'accept_selected' or 'accept_all'
                            },
                            secondary_btn: {
                                text: 'Nesouhlasím',
                                role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                            }
                        },
                        settings_modal: {
                            title: 'Nastavení cookies',
                            save_settings_btn: 'Uložit nastavení',
                            accept_all_btn: 'Souhlasím se vším',
                            reject_all_btn: 'Odmítnout vše',
                            close_btn_label: 'Close',
                            cookie_table_headers: [
                                {col1: 'Name'},
                                {col2: 'Domain'},
                                {col3: 'Expiration'},
                                {col4: 'Description'}
                            ],
                            blocks: [
                                {
                                    title: 'Použití cookies 📢',
                                    description: 'Tyto soubory používáme k zajištění základních funkcí webových stránek a ke zlepšení dostupnosti zobrazovaných informací. U každé kategorie si můžete zvolit, zda se chcete přihlásit nebo odhlásit, kdykoli budete chtít.'
                                }, {
                                    title: 'Základní cookies',
                                    description: 'Tyto soubory jsou nutné pro základní fungování stránek, bez nich by nefungovaly ani základní funkce.',
                                    toggle: {
                                        value: 'necessary',
                                        enabled: true,
                                        readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                                    }
                                }, {
                                    title: 'Analytické a marketingové cookies',
                                    description: 'Tyto cookies umožňují sbírat informace o procházení stránek, abychom mohli jejich možnosti neustále vylepšovat',
                                    toggle: {
                                        value: 'analytics',     // your cookie category
                                        enabled: false,
                                        readonly: false
                                    },
                                }, {
                                    title: 'Další informace',
                                    description: 'Pro další informace nás můžete kontaktovat na e-mailu <a class="cc-link" href="mailto:plysovnik@plysovnik.cz">plysovnik@plysovnik.cz</a>.',
                                }
                            ]
                        }
                    }
                }
            });

            return () => {
                const element = document.getElementById('cc_div');
                element.parentNode.removeChild(element);    
            }
 
    }, []);

    return null;
}