import React  from "react"; 
import GoodsCategories from "./GoodsCategories" 
import { useStaticQuery, graphql } from "gatsby" 

export const Series = ({setOpen}) => {
    const data = useStaticQuery(graphql`
    query GoodsCategories {
      scomos {
        goodCategoryFindAll(menu:"serie") {
            id,
            name,
            url,
            parentId,
            lft,
            rgt,
          }
      }
    }, 
  `)
  
    let categories = data.scomos.goodCategoryFindAll
    
 
    return (
        <>
         
              
                <GoodsCategories categories={categories} setOpen={setOpen}  />
 
        </>
    )
}