import { ChevronRightIcon } from "@heroicons/react/solid"
import React  from "react"
export const ColorButton = ({icon,text}) => {
    
    return( 
        <>
         <button className={"flex relative items-center space-x-4 px-4 py-3 text-gray-100 bg-blue-600 rounded-md border border-blue-700   hover:text-white hover:bg-blue-700 focus:outline-none shadow-md overflow-hidden " + (icon && "pl-16 ")}>
            {icon && <span className="w-16 h-12  bg-gray-800 bg-opacity-30 p-4 py-2 absolute left-0">{icon}</span>}
            <span className="flex justify-between items-center w-full">{text}
            <ChevronRightIcon className="w-5 h-5 ml-5" />
            </span>
        </button>
        </>
    )
}