import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const Product = ({item}) => { 
    return (
        <Link key={item.id} to={'/plysaci/detail/' + item.alias + '/'}  className="grid  grid-cols-3 bg-white hover:bg-gray-100 shadow-md rounded-md">
            <div className="relative  rounded-md overflow-hidden m-3 ">
                <div className="w-full h-full  ">
                    <div className="w-full h-full  object-center object-cover  ">
                        <GatsbyImage className="  object-contain lg:object-cover "
                            image={item.localFile?.childImageSharp?.gatsbyImageData}
                            alt={item.name}
                        />
                    </div>
                    
                </div>
                <div className="absolute top-0 right-0 left-0 bottom-0 inset-0 bg-black bg-opacity-5 w-full h-full group-hover:bg-opacity-10" />
                
            </div>
            <div className="p-5 flex items-center col-span-2">
                {item.name}
            </div>
        </Link>
    )
}

export const TopProducts = () => { 
    const data = useStaticQuery(graphql`
    query TopProductsQuery {
        products: allProduct(filter: {productId: {in: ["5306","5416","5372","317","5222","5212","5111","5155"]}}) {
          nodes {
            id
            productId
            name
            alias
            localFile{
             childImageSharp {
                gatsbyImageData
             }
           }
          }
        } 
    }
  `)
  
    //const [products, setProducts] = useState([]);
    const products = data.products.nodes
    
    // useEffect(() => { 
    //     const goods = data.scomos 
    //     let newProducts = [];
    //     Object.keys(goods).forEach(function(key, index) {
    //         let imgData
    //         images.forEach((img) => {
    //             if(img.productId === goods[key].id) {
    //                 imgData = img.localFile
    //             }  
    //         });
                
    //         let newProduct = {
    //             ...goods[key],
    //             imgData: imgData 
    //         }
    //         newProducts.push(newProduct);
    //     });
    //     setProducts(newProducts);
    //     console.log(products);
    // }
    //   // eslint-disable-next-line 
    // , []);
      
    
 
     
    return (
      <>
       <div className="py-3 pb-5  bg-gray-200 border-b border-white  ">
            <div className=" max-w-7xl mx-auto p-5 py-2 grid grid-cols-2  lg:grid-cols-4 gap-4">
                {products.map((product) => ( 
                    <Product key={product.id} item={product} /> 
                ))}
            </div>
        </div>
      </>
    )
  }