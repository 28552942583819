import React, {useContext, useEffect} from "react"; 
import {Product} from "./Product"; 
import { navigate } from "@reach/router"  
import { LoadingProducts } from "./LoadingProducts";
import { useQuery } from "@apollo/client";
import { GOODS_LIST, GOODS_SEARCH } from "../../query/goods";
import ReactPaginate from 'react-paginate'; 
import PageContext from "../../context/PageContext";
import { Link, useStaticQuery, graphql } from "gatsby"; 
import { SubCats } from "../layout/SubCats"; 
import { EmptyList } from "../elements/EmptyList"; 
import { ColorButton } from "../layout/ColorButton";
import { Helmet } from "react-helmet";

export const ProductsList = ({cat, producer, page, params, orderBy, location, paging, hideHeader }) => {

    const fileData = useStaticQuery(graphql`
        query FileQuery {
            allProduct {
                nodes {
                  id
                  productId
                  name
                  localFile{
                   childImageSharp {
                      gatsbyImageData
                   }
                 }
              }
           }
        }
        
    `) 

        
    const [context, dispatch] = useContext(PageContext); 
    const searchString = context?.searchString;
    const storage = context?.storage;
    const breadCrumb = context?.breadCrumbPath;
    let title = "Plyšové hračky"
    if(breadCrumb && breadCrumb.length > 0) {
        breadCrumb.forEach((cat) => {
            title = title + " > " + cat.name;
        });
    }

    let order = [];
    if(searchString) {
        order.push('quantity:DESC')
        order.push('priceVat:DESC')
        order.push('updated:DESC')
    } else {
        order = orderBy;
    }
 
    function storageChange() {
        dispatch({
            type: 'SET_STORAGE',
            payload: {
                storage: !context?.storage
            }
        })
    }

    const StorageButton = ({storage}) => {
        
        return (
            <div role="button" tabIndex={0} className="inline-flex items-center sm:col-span-4 cursor-pointer w-auto mr-2" onClick={() => storageChange()} onKeyUp={() => storageChange()}>
                <button  
                type="button" aria-pressed="false" aria-labelledby="toggleLabel" 
                className={(storage ? 'bg-green-600' : 'bg-gray-300' ) + " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "}
                >
                    <span className="sr-only">jen skladem</span>
                    <span aria-hidden="true" 
                    className={(storage ? 'translate-x-5' : 'translate-x-0' ) + " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}>
                    </span>
                </button>
                <span className={(storage ? 'text-gray-900 font-bold' : 'text-gray-400' ) + " text-sm flex space-x-2 ml-3"} id="toggleLabel">
                    <span >pouze skladem</span>                                   
                </span>
            </div>
        )
    }
 
  
    let offset = 0;
    if(page>1) {
        offset = (paging*page)-paging;
    }

    let queryName = GOODS_LIST;
    if(searchString) {
        queryName = GOODS_SEARCH;
    }
 
    const {loading, data} = useQuery(queryName, {
        variables: {
            cat: cat ?? null,
            limit: paging,
            offset: offset,
            orderBy: order,
            search: searchString,
            params: params,
            storage: context?.storage
        }
    });
 

    const handlePageClick = (e) => {
        let url = '/plysaci/' 
        const selectedPage = e.selected+1; 
        if(cat) {
            url = url + cat + '/'
        }
        if(selectedPage===1) {
            navigate(url + location.search)
        } else {
            navigate(url + 'strana/' + selectedPage + '/' + location.search)
        }
         
      }; 

    let items = data?.goodFindAll || [];
    let newItems = [];  
    items && items.forEach((item, itemIdx) => {
        let imgData 
        fileData.allProduct.nodes.forEach(node => {
            if(node.productId === item.id) {
                imgData = node.localFile
            } 
        })
        let newProduct = {
            ...item,
            imgData: imgData
        }
        newItems.push(newProduct); 
    });
    items = newItems; 
    const catData = cat && data?.goodCategoryFindByUrl ? data?.goodCategoryFindByUrl[0] : [];
    const path = cat ? data?.goodCategoryGetPath : [];
    const subCats = cat ? data?.goodCategoryFindAll : [];
    const pageCount = Math.ceil(data?.goodGetCount/paging) ?? 0;

    useEffect(() => { 
        !hideHeader &&
        dispatch({
            type: 'SET_BREADCRUMB',
            payload: {
                breadCrumbPath: path
            }
        })
 
        // eslint-disable-next-line
    }, [cat, data]); 
  
    return (
        <> 
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title> 
        </Helmet>
        {/* <div>

            <ProductFilter cat={cat} catTags={params} params={params}  openStatus={openStatus} setOpenStatus={setOpenStatus} />
        </div>   */}

            {/* <div className={"mt-4 w-full text-gray-500 sm:mt-6 " + (!loading && items && items.length > 0 ? "block" : "hidden")}> */}
    <div className=" ">
        <div className=" " >

            <div className={" w-full text-gray-500 pt-5 "  }>
                {!hideHeader &&
                
                <div className="  ">
                    <div className="max-w-7xl mx-auto px-5">                       
                        <div className="flex justify-between w-full items-center h-14"> 
                            {!cat && !searchString ?
                            <div className="text-red-600 text-2xl lg:text-2xl lg:px-2 font-bold ">
                                Plyšové hračky {producer}
                            </div>
                            : searchString ? 
                            <div className="text-red-600 text-2xl lg:text-2xl lg:px-2 font-bold">
                                Plyšová hračka: <Link className="underline hover:no-underline" to={'/vyhledavani/?s=' + searchString}>{searchString}</Link>
                            </div>
                            :
                            
                            <>
                            <div className="text-red-600 text-2xl lg:text-2xl lg:px-2 font-bold ">
                                {catData?.name}
                            </div>
                            
                            </>
                            } 

                            <div>
                                {!searchString && <StorageButton storage={storage} />}

                            </div>
                        </div>
                        
                        
                        {catData?.content && 
                            <div dangerouslySetInnerHTML={{__html: catData.content}} className=" rounded-sm  py-5 prose-sm prose-amber   lg:px-2  text-gray-500 mx-auto  "> 
                                    
                            </div>
                        }
                        <div>
                            <SubCats cats={subCats} />
                        </div>
                    </div>
                </div>
                }

                {items && items.length === 0 && loading &&  <LoadingProducts />}
                {items && items.length === 0 && !loading &&  
                    <>  
                        {searchString 
                            ? 
                            <EmptyList text={'Nic nebylo nalezeno, zkuste změnit zadání'} />
                            :
                            <EmptyList text={'Žádné položky'} />
                        }
                        {storage &&  
                            <>
                            <div className="mt-6 grid justify-center   max-w-7xl mx-auto  px-5">
                                
                                 <button onClick={storageChange}>
                                    <ColorButton text={"Zobrazit i plyšáky, kteří nejsou skladem"} />
                                 </button>

                            </div>
                            </>
                        }
                    </>
                    
                }
                    
                    
                <div className="mt-6 grid grid-cols-2 gap-y-8 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8 max-w-7xl mx-auto  px-5">
                    
                    {items && items.length > 0 && items.map((item,itemIdx) => (
                        <Product key={itemIdx} item={item} list={items} itemIdx={itemIdx} hasMore={false} lastElementRef={null} location={location} searchString={searchString} />
                    ))}

                </div>

            </div>
                  
            {/* {items && items.length === 0 && !loading && <EmptyList text={'Žádné položky'} />}

            {error && <p>Error!</p>} */}


            {/* {loading && <Spin loading={loading} />} */}

            {!loading && pageCount > 1 && !hideHeader && 
            <div className="px-4 py-10 grid items-center justify-items-center mt-3  sm:px-6">
                <ReactPaginate  
                    previousLabel={
                        <>
                        
                            <>
                            <svg className="h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                            <span className="inline-flex sm:hidden">Předchozí</span>
                            </>
                       
                        </>
                    }
                    nextLabel={
                        <>
                        <span className="inline-flex sm:hidden">Následující</span>
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                        </>
                    }
                    previousClassName={"relative rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none flex-1 flex justify-between " +  (page === 1 && "hidden") }
                    previousLinkClassName={" px-2 py-2  focus:outline-none  inline-flex items-center"}
                    nextClassName={"relative  rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none " 
                    +  (page === 1 && "rounded-l-md lg:rounded-l-none ") 
                    +  (page === pageCount && " hidden") }
                    nextLinkClassName={"px-2 py-2 focus:outline-none inline-flex items-center"}
                    breakLabel={"..."}
                    breakClassName={"relative  sm:inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"}
                    pageCount={pageCount} 
                    forcePage={page - 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"relative z-0 inline-flex  rounded-md shadow-sm -space-x-px select-none "}
                    subContainerClassName={""}
                    pageClassName={"hidden sm:inline-flex relative    border border-gray-300 bg-white text-sm font-medium  hover:bg-gray-50 " }
                    pageLinkClassName={"px-4 py-2 text-gray-700 focus:outline-none"}
                    activeClassName={"bg-amber-200 hover:bg-amber-300 "}
                    />
                </div>  
            }
            </div>  
        </div>  
        </>
    )
}

export default ProductsList;