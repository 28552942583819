
import React, { Fragment } from "react";  
import { Transition, Popover } from '@headlessui/react'; 
import { ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import { Link } from "gatsby"  

export default function GoodsCategories({cat, categories, setOpen}) {
    function closeMenu(close) {
        setOpen(false)
        close()
      }
      
      return ( 
        <> 

<div className="lg:relative  text-sm"  >
    <Popover >
    {({ open, close }) => (
      <>  
          <div className=" flex lg:relative "  
             >
            <Popover.Button 
             className={"w-full  rounded-md flex items-center  focus:outline-none text-gray-600  lg:text-gray-100  hover:bg-amber-500   hover:text-gray-100   " 
             + (open ? ' bg-amber-500   '  : ' ')} id="user-menu" >
                <div className="relative flex  items-center py-2 px-2 w-full justify-between " >
                    
                    <div className="flex items-center  ">    
                    {/* <ViewGridIcon className="w-5 h-5" /> */}
                    <span className="truncate">
                        Série
                    </span>
                    </div>
                    <ChevronDownIcon className="w-4 h-4 hidden lg:block" />
                    <ChevronRightIcon className="w-4 h-4 block lg:hidden" />
                    </div>
            </Popover.Button>
          </div>
        
          <Transition
          show={open} 
          enter=" duration-150 ease-out "
          enterFrom="opacity-0 "
          enterTo="opacity-100 "
          leave=" duration-150 ease-in "
          leaveFrom="opacity-100 "
          leaveTo="opacity-0 "
          > 
          <div className="  fixed w-full  top-0 h-full inset-0 bg-black bg-opacity-25 lg:hidden" aria-hidden="true" onClick={() => close() }></div>
          </Transition>
        
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-x-full lg:translate-x-0 lg:scale-95"
          enterTo="opacity-100 translate-x-0  lg:scale-100"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-x-0 lg:scale-100"
          leaveTo="opacity-0 translate-x-full lg:translate-x-0 lg:scale-95"
        >
          <Popover.Panel 
              static 
              className="absolute h-screen lg:h-auto   top-0 lg:top-10 right-0 left-16 lg:left-0 lg:absolute z-50 inset-x-0 transition transform lg:shadow-lg w-80  lg:w-80">
            
            <div className="h-full ">
              <nav
                className="h-full bg-white  border pb-10 lg:rounded-md lg:px-3"
              >
              <div className="w-full py-5 lg:hidden">
                <button className="px-3" onClick={close}>
                  <ChevronLeftIcon className="w-10 h-10 text-gray-500"/>
                </button>
              </div> 
                <div>
                 <ul className="my-3 ">  
                    {categories && categories.map((category,itemIdx) => (
                      <li key={itemIdx} className="flow-root"> 
                        <Link
                          onClick={() => closeMenu(close) }
                          to={'/plysaci/' + category.url} partiallyActive={category.url==='' ? (false) : (true)} 
                          className="p-3 flex items-center rounded-md  font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150 w-full "
                          activeClassName=" text-red-700 hover:text-blue-600 border-blue-600 font-bold "
                        >
                          <span className="ml-4">{category.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div> 
              </nav> 
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}
  </Popover>  
  </div>

        
        </>
      )

   }